import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db, storage, auth } from "../firebaseConfig";
import { Button, Upload, message, Modal } from "antd";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImageHelper"; // Helper function to crop images

const UsernameInput = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { uid, email } = location.state || {};  
  const [error, setError] = useState(null);
  const currentUser = auth?.currentUser;
  const currentURL = `${window.location.origin}/`;
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [username, setUsername] = useState("");
  const [usernameAvailable, setUsernameAvailable] = useState(null);

  // Cropper states
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cropModalVisible, setCropModalVisible] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (currentUser?.uid) {
      const subscriber = db
        .collection("userss")
        .doc(currentUser.uid)
        .onSnapshot((doc) => {
          setProfileData(doc.data());
        });

      return () => subscriber();
    }
  }, [currentUser]);

 
  const handleSaveUsername = async () => {
    const finalUsername = username || profileData?.username;

    if (!currentUser?.uid || !finalUsername) {
      setError("Please provide a valid username.");
      return;
    }

    if (!usernameAvailable) {
      setError("Username is already taken. Please choose a different one.");
      return;
    }

    try {
      await updateDoc(doc(db, "userss", currentUser.uid), { username: finalUsername });
      await setDoc(doc(db, "usedUsernames", finalUsername), { uid: currentUser.uid, timestamp: new Date() });

      if (croppedImage) {
        await handleImageUpload();
      }

      navigate("/profile");
    } catch (error) {
      console.error("Error updating username:", error);
      setError("An error occurred while saving your username.");
    }
  };


  const [originalImage, setOriginalImage] = useState(null);
  const [useOriginalImage, setUseOriginalImage] = useState(false);

  const validateUsername = (input) => {
    return input.toLowerCase().replace(/[^a-z0-9._]/g, ""); 
  };

  const checkUsernameAvailability = async (input) => {
    const cleanUsername = validateUsername(input);
    if (!cleanUsername) {
      setUsernameAvailable(null);
      return;
    }

    try {
      const usedUsernameDoc = await getDoc(doc(db, "usedUsernames", cleanUsername));
      setUsernameAvailable(!usedUsernameDoc.exists());
    } catch (error) {
      console.error("Error checking username:", error);
    }
  };

  const handleUsernameChange = (e) => {
    const cleaned = validateUsername(e.target.value);
    setUsername(cleaned);
    checkUsernameAvailability(cleaned);
  };

  /** ✅ Handle Image Selection */
  const onSelectFile = (file) => {
    if (file) {
      setOriginalImage(file); // Store the original image
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImageSrc(reader.result);
        setCropModalVisible(true);
      };
    }
  };

  /** ✅ Handle Cropping */
  const onCropComplete = useCallback(async (_, croppedAreaPixels) => {
    try {
      const croppedImageFile = await getCroppedImg(imageSrc, croppedAreaPixels);
      setCroppedImage(croppedImageFile);
      setUseOriginalImage(false); // Ensure we use cropped image when user confirms cropping
    } catch (error) {
      console.error("Crop error:", error);
    }
  }, [imageSrc]);

  /** ✅ Handle Upload */
  const handleImageUpload = async () => {
    setLoading(true);
    try {
      const imageRef = ref(storage, `profileImages/${currentUser.uid}`);
      let fileToUpload;

      if (useOriginalImage && originalImage) {
        fileToUpload = originalImage;
      } else if (croppedImage) {
        fileToUpload = croppedImage;
      } else {
        message.error("No image selected for upload.");
        return;
      }

      await uploadBytes(imageRef, fileToUpload);
      const imageUrl = await getDownloadURL(imageRef);

      await updateDoc(doc(db, "userss", currentUser.uid), { profileImage: imageUrl });

      setProfileData((prevData) => ({ ...prevData, profileImage: imageUrl }));
      message.success("Profile image updated successfully!");
    } catch (error) {
      message.error("Error uploading image: " + error.message);
    } finally {
      setLoading(false);
      setCropModalVisible(false);
    }
  };


  return (
    <div className="min-h-screen flex flex-col justify-center p-3 items-center bg-white dark:bg-black dark:text-white">
      <h2 className="text-2xl font-bold mb-2 mt-20">Welcome to VFA GALLERY!</h2>
      <p className="mx-5">Create your VFA username. You can always change it later.</p>

      <div className="flex justify-center items-center m-4 mb-0">
        {profileData?.profileImage ? (
          <img src={profileData.profileImage} alt="Profile" className="w-24 h-24 rounded-full object-cover" />
        ) : (
          <div className="w-24 h-24 rounded-full bg-gray-400 flex text-white items-center justify-center text-2xl">
            {"O"}
          </div>
        )}
      </div>
      <p className="">{profileData?.username}</p>

      <div className="text-center">
        <Upload
          beforeUpload={(file) => {
            onSelectFile(file);
            return false;
          }}
          listType="picture"
          maxCount={1}
        >
          <Button type="dashed" className="text-[#555]">Select Profile Image</Button>
        </Upload>

        {croppedImage && (
          <Button
            type="primary"
            className="bg-gradient-to-r from-violet-600 to-indigo-600 border-none text-white mt-4"
            onClick={handleImageUpload}
            loading={loading}
          >
            Upload Image
          </Button>
        )}
      </div>

      <input
        type="text"
        value={username}
        onChange={handleUsernameChange}
        placeholder="Enter your username"
        className="px-4 py-2 mb-2 mt-5 dark:text-white dark:bg-[#111] h-12 border w-80 outline-blue-400 rounded-lg"
      />
      {username && (
        <p className={`text-sm pb-2 ${usernameAvailable ? "text-green-500" : "text-red-500"}`}>
          {usernameAvailable ? "✅ Username available" : "❌ Username taken"}
        </p>
      )}
      {error && <p className="text-red-500">{error}</p>}

      <button
        onClick={handleSaveUsername}
        className="px-6 py-3 bg-gradient-to-r from-violet-600 to-indigo-600 w-60 mb-3 text-white rounded-full"
        disabled={!usernameAvailable}
      >
        Continue
      </button>

      <button onClick={() => navigate("/profile")} className="px-6 py-2 w-60 border-2 rounded-full">
        Skip
      </button>

      <Modal open={cropModalVisible} onCancel={() => setCropModalVisible(false)}>
        <div className="relative w-full h-80">
          <Cropper image={imageSrc} crop={crop} zoom={zoom} onCropChange={setCrop} onZoomChange={setZoom} onCropComplete={onCropComplete} />
        </div>
        <div className="flex justify-between mt-4">
          <Button onClick={() => { setUseOriginalImage(true); setCropModalVisible(false); }} className="bg-gray-500 text-white">
            Use Original Image
          </Button>
          <Button type="primary" onClick={() => setCropModalVisible(false)} className="bg-indigo-600 text-white">
            Confirm Crop
          </Button>
        </div>
      </Modal>
      
    </div>
  );
};

export default UsernameInput;
