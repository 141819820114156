import React, { useEffect, useState } from "react";
import { collectionGroup, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore";
import { collection, onSnapshot } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage, auth } from "../firebaseConfig";
import { Button, Upload, message, Tooltip, Input } from "antd";
import { CopyOutlined, ShareAltOutlined } from "@ant-design/icons";
import { FaPlusCircle } from "react-icons/fa";
import { BiChip, BiImage } from "react-icons/bi";
import { Link } from "react-router-dom";
import firebase from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import Shop from "./Shop";
import QRCode from "react-qr-code";
import { BsArrowDown, BsCheckCircleFill } from "react-icons/bs";
import { HiCheckBadge } from "react-icons/hi2";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";



const Profile = () => {

  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [Show, setShow] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [linksData, setLinksData] = useState([]);
  const [editedLinks, setEditedLinks] = useState({});
  const currentUser = auth.currentUser;
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser?.uid) return;

    const userDocRef = doc(db, "userss", currentUser.uid);
    const linksCollectionRef = collection(userDocRef, "Links");
    const orderedLinksQuery = query(linksCollectionRef, orderBy("createdAt", "asc"));

    const unsubscribeUser = onSnapshot(userDocRef, (doc) => {
      setProfileData(doc.data());
    });

    const unsubscribeLinks = onSnapshot(orderedLinksQuery, (snapshot) => {
      const links = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLinksData(links);
    });

    return () => {
      unsubscribeUser();
      unsubscribeLinks();
    };
  }, [currentUser]);

  const handleSignOut = () => {
    auth.signOut().then(() => {
      localStorage.removeItem("userData");
      navigate("/");
    }).catch((error) => console.error(error));
  };

  const handleCopyLink = () => {
    const profileLink = `${window.location.origin}/${profileData.username}`;
    navigator.clipboard.writeText(profileLink);
    message.success("Profile link copied!");
  };

  /** 🔹 Handle Editing Links */
  const handleNameChange = (id, value) => {
    setEditedLinks((prev) => ({
      ...prev,
      [id]: { ...prev[id], name: value },
    }));
  };

  const handleUrlChange = (id, value) => {
    setEditedLinks((prev) => ({
      ...prev,
      [id]: { ...prev[id], url: value },
    }));
  };

  const handleDescriptionChange = (id, value) => {
    setEditedLinks((prev) => ({
      ...prev,
      [id]: { ...prev[id], description: value },
    }));
  };

  const handleImageUpload = async (file, id) => {
    try {
      const imageRef = ref(storage, `linkImages/${currentUser.uid}/${id}`);
      await uploadBytes(imageRef, file);
      const imageUrl = await getDownloadURL(imageRef);

      setEditedLinks((prev) => ({
        ...prev,
        [id]: { ...prev[id], image: imageUrl },
      }));
    } catch (error) {
      message.error("Image upload failed!");
    }
  };

  const handleSaveChanges = async (id) => {
    try {
      const linkDocRef = doc(db, "userss", currentUser.uid, "Links", id);
      await updateDoc(linkDocRef, {
        name: editedLinks[id]?.name || linksData.find((link) => link.id === id)?.name,
        image: editedLinks[id]?.image || linksData.find((link) => link.id === id)?.image,
        url: editedLinks[id]?.url || linksData.find((link) => link.id === id)?.url,
        description: editedLinks[id]?.description || linksData.find((link) => link.id === id)?.description || "",
      });
      message.success("Updated successfully!");
    } catch (error) {
      message.error("Failed to update link!");
    }
  };
  const handleClear = async (id) => {
    try {
      const linkDocRef = doc(db, "userss", currentUser.uid, "Links", id);
      await updateDoc(linkDocRef, {
        url: "",
        description: "",
      });
      message.success("Cleared successfully!");
    } catch (error) {
      message.error("Failed to update link!");
    }
  };

  const currentURL = `${window.location.origin}/`;
  const [inputValue, setInputValue] = useState("");

  const [user, setUser] = useState(null);

  const [CSuser, setCSUser] = useState();
  useEffect(() => {
    if (!currentUser?.uid) return;
  
    const userDocRef = doc(db, "userss", currentUser.uid);
    const linksCollectionRef = collection(userDocRef, "Links");
  
    // Query to order the Links by a timestamp field in descending order
    const orderedLinksQuery = query(linksCollectionRef, orderBy("createdAt", "asc"));
  
    const unsubscribeUser = onSnapshot(userDocRef, (documentSnapshot) => {
      setProfileData(documentSnapshot.data());
    });
  
    const unsubscribeLinks = onSnapshot(orderedLinksQuery, (snapshot) => {
      const links = snapshot.docs.map((doc) => ({
        id: doc.id, // Include the document ID if needed
        ...doc.data(),
      }));
      setLinksData(links); // Assuming setLinksData is a state updater for Links
    });
  
    return () => {
      unsubscribeUser();
      unsubscribeLinks();
    };
  }, [currentUser]);


  useEffect(() => {
    
    // Check if user data exists in local storage
    const userData = localStorage.getItem("userData");
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Cleanup the event listener on unmount
  }, []);

  // const [loading, setLoading] = useState(false);
  const handleDeleteProfile = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete your profile? This action is irreversible."
    );

    if (!confirmDelete) return; // If user cancels, do nothing

    setLoading(true);
    const user = auth().currentUser;

    if (user) {
      try {
        await user.delete(); // Deletes Firebase authentication account
        window.alert("Your profile has been permanently deleted.");
        window.location.href = "/"; // Redirect to home or login page
      } catch (error) {
        if (error.code === "auth/requires-recent-login") {
          window.alert(
            "For security reasons, please log in again before deleting your account."
          );
          // Redirect to login page if needed
          window.location.href = "/login";
        } else {
          window.alert("Error: Unable to delete account. Please try again later.");
        }
      }
    }
    setLoading(false);
  };
  

  const updateFilteredLinksImages = async () => {
    try {
        const linksCollectionGroupRef = collectionGroup(db, "Links");

        // Query to filter where name is "Link" or "VCard Link"
        const linksQuery = query(linksCollectionGroupRef, where("name", "in", ["Link", "VCard Link"]));
        const querySnapshot = await getDocs(linksQuery);

        const newImageURL = "https://cdn1.iconfinder.com/data/icons/unicons-line-vol-4/24/link-alt-512.png";

        const updatePromises = querySnapshot.docs.map(async (doc) => {
            await updateDoc(doc.ref, { image: newImageURL });
        });

        await Promise.all(updatePromises);

        console.log("Filtered Links images updated successfully.");
    } catch (error) {
        console.error("Error updating filtered Links images:", error);
    }
};



  //  // fetch the user data from firebase
  //  useEffect(() => {
  //   const subscriber = db
  //       .collection('userss')
  //       .doc(currentUser?.uid)
  //       .onSnapshot(documentSnapshot => {
  //         setProfileData(documentSnapshot.data());
  //       });
    
  //   // Stop listening for updates when no longer required
  //   return () => subscriber();
  // }, [currentUser]);

  // const handleShare = () => {
  //   if (navigator.share) {
  //     navigator.share({
  //       title: `${profileData.username}'s Profile`,
  //       url: `${currentURL}${profileData.username}`,
  //     })
  //     .then(() => message.success("Profile shared successfully!"))
  //     .catch((error) => message.error("Error sharing profile: " + error));
  //   } else {
  //     message.warning("Share feature not supported on this browser.");
  //   }
  // };

  // const uploadProps = {
  //   beforeUpload: (file) => {
  //     setProfileImage(file);
  //     return false;
  //   },
  //   onRemove: () => {
  //     setProfileImage(null);
  //   },
  // };

  // const uid = profileData?.uid
  // const usernames = profileData?.username
  // const email = profileData?.email
  // console.log(uid, usernames, email)

  // const [urls, setUrls] = useState(
  //   linksData.reduce((acc, link) => {
  //     acc[link.id] = link.url || ""; // Initialize state with existing URLs or empty strings
  //     return acc;
  //   }, {})
  // );

  // const handleInputChange = (id, value) => {
  //   setUrls((prevUrls) => ({
  //     ...prevUrls,
  //     [id]: value,
  //   }));
  // };

  // const handleSaveUrl = async (id) => {
  //   try {
  //     const linkDocRef = doc(db, "userss", currentUser.uid, "Links", id);
  //     await updateDoc(linkDocRef, { url: urls[id] });
  //     alert("URL updated successfully!");
  //   } catch (error) {
  //     console.error("Error updating URL:", error);
  //     alert("Failed to update URL.");
  //   }
  // };



  if (!profileData) {
    return <div className="dark:text-white w-[100%] h-[100%]">Loading profile...
      {/* <button
            onClick={handleSignOut}
            className=" bg-gradient-to-r from-violet-600 to-indigo-600 z-50 m-20 p-1 rounded-lg"
          >
                      <p className="px-12 py-1 flex-row text-white flex items-center" >
            Sign Out
            </p>
          </button> */}
    </div>;
  }

  

  return (
    <>
          {/* <img className="fixed -z-0 h-screen w-screen lg:blur-2xl" src={profileData?.profileImage} alt="bg-image"  /> */}
         
          <div className="flex justify-center">
          {
          profileData?.profileCover &&
          <>
          
                   <img className="absolute z-10 lg:w-[40%] blur w-full h-screen lg:rounded-2xl" src={profileData?.profileCover} alt="bg-image"  />
                   <img className="absolute -z-0 blur-2xl w-full h-[70%] lg:rounded-2xl" src={profileData?.profileCover} alt="bg-image"  />

 {/* Dark Mode Gradient (Black) or Light Mode Gradient (White) */}
 <div className="absolute -bottom-32 left-0 w-full z-20 h-[150%]
    bg-gradient-to-t 
    from-white to-white/0 dark:from-black dark:to-black/0"
    style={{ height: "150%", maskImage: "linear-gradient(to top, black 50%, transparent 100%)", WebkitMaskImage: "linear-gradient(to top, black 50%, transparent 150%)" }}>
  </div>
      </>
    }


    <div 
        className="lg:pt-24 pt-20 dark:text-white p-0 flex flex-col z-30 items-center">
      <div className=" rounded-lg shadow-lg z-30 w-full py-5">
        <div className="flex justify-center items-center mb-1">
          {/* <p className="font-semibold m-2 text-sm dark:text-[#ccc]">{`Token balance ${profileData.balance}`}  </p> */}
          {profileData?.profileImage ? (
            <img
            src={profileData?.profileImage}
            alt="Profile"
            className="w-24 h-24 rounded-full object-cover"
            />
          ) : (
            <div className="w-24 h-24 rounded-full bg-gray-400 text-white flex items-center justify-center text-2xl">
              {profileData?.username[0]?.toUpperCase()}
            </div>
          )}
                      <div className="w-24 h-24 ml-3 p-2 rounded-md bg-white flex items-center justify-center">
          <QRCode
              id={`qr-${profileData?.username}`}
              value={`${currentURL}/${profileData?.username}`} size={150} />
        </div>
        </div>
          <p className=" font-bold dark:text-white flex self-center justify-center text-center items-center">{profileData?.username}
              <HiCheckBadge className="ml-0 text-blue-500" />
            </p>

        <div className="text-center justify-center items-center flex mb-0 mt-0">
        
            <Link
            // type="primary"
            onClick={() => setShow(!Show)}
            className="bg-gradient-to-r from-violet-600 to-indigo-600 border-none px-4 justify-center items-center w-32 m-2 text-sm p-2 flex rounded-lg text-white"
          >
           {Show ? "Hide" : "Reveal"} {Show ? <FaArrowUp  className="ml-2"/> : <FaArrowDown className="ml-2"/>} 
          </Link>

          <Link
            // type="primary"
            // onClick={() => navigate("/username-input", { state: { uid, email, usernames } })}
            to={"/rewards"}
            className="bg-gradient-to-r from-violet-600 to-indigo-600 border-none px-4 m-2 text-sm p-2 rounded-lg text-white"
          >
            Social Commerce
          </Link>
           
        </div>

        { Show &&
          <>
                  {/* Profile buttons Section */}

        <div className="text-center my-1">
          <p className="text-sm mb-4 dark:text-gray-300">{profileData?.email || "User email"}</p>
            <Link
            // type="primary"
            // onClick={() => navigate("/username-input", { state: { uid, email, usernames } })}
            to={"/username-input"}
            // onClick={updateFilteredLinksImages}
            className="bg-gradient-to-r from-violet-600 to-indigo-600 border-none px-4 ml-2 text-sm p-2 rounded-lg text-white"
          >
            Edit
          </Link>

            <Link
            // type="primary"
            // onClick={() => navigate("/username-input", { state: { uid, email, usernames } })}
            to={"/cover"}
            className="bg-gradient-to-r from-violet-600 to-indigo-600 border-none mx-2 px-4 text-sm p-2 rounded-lg text-white"
          >
            Cover-Photo
          </Link>
          
            <Link
            // type="primary"
            // onClick={() => navigate("/username-input", { state: { uid, email, usernames } })}
            to={`${currentURL}${profileData.username}`}
            className="bg-gradient-to-r from-violet-600 to-indigo-600 border-none px-4 text-sm p-2 rounded-lg text-white"
          >
            Preview
          </Link>
        </div>
        
        <Tooltip title="Copy Profile Link">
          <Button
            icon={<CopyOutlined />}
            type="text"
            onClick={handleCopyLink}
            className="dark:text-white font-semibold w-full text-xs text-center"
          >
            {currentURL + profileData.username}
          </Button>
        </Tooltip>

        <div className="mb-5 flex justify-center">
        


          <Link
            // icon={<BsCart />}
            // type="primary"
            // onClick={handleShop}
            to="https://apps.apple.com/app/wear2earn-nycc/id6740171763"
            // href="/setup"
            // href="https://apps.apple.com/us/app/nfc-tools/id1252962749"
            // href="https://www.wakdev.com/en/apps.html"
            className="bg-gradient-to-r from-violet-600 to-indigo-600 text-sm  border-none items-center text-center justify-center self-center flex p-2 px-4 ml-2 rounded-lg text-white"
          >
            <BiChip />
            <p className="ml-1">
            Write my Chip
            </p>
          </Link>
        </div>
          </>

        }




      

        {/* Social Links Section */}
        <div className="rounded-md p-4 mb-6 lg:px-[30%] justify-center items-center self-center">
          {/* <h3 className="text-lg font-semibold mb-3 text-center">Add Links</h3> */}
      
        <div className="grid gap-3 mb-4">
          {linksData.map((link) => (
            <div key={link.id} className="text-start flex items-center justify-center">
              <div className="text-start w-full items-center p-5 rounded-2xl dark:bg-[rgba(52,52,52,0.3)] shadow-2xl">
                {/* 🔹 Editable Image Upload */}
                <div className="flex items-center mb-2">
                  <Upload showUploadList={false} beforeUpload={(file) => { handleImageUpload(file, link.id); return false; }}>
                    <img src={editedLinks[link.id]?.image || link.image} alt="Logo" className="w-10 cursor-pointer rounded-lg" />
                  </Upload>
                  {/* 🔹 Editable Name Input */}
                  <Input value={editedLinks[link.id]?.name || link.name} onChange={(e) => handleNameChange(link.id, e.target.value)}
                    className="ml-2 dark:bg-[#222] bg-[#eee] p-2 rounded-lg w-full border-0 text-sm dark:text-white" />
                </div>

                {/* 🔹 Editable URL Input */}
                <Input type="text" value={editedLinks[link.id]?.url || link.url || ``}
                  onChange={(e) => handleUrlChange(link.id, e.target.value)}
                  className="dark:bg-[#222] bg-[#eee] p-2 rounded-lg text-sm w-full border-0 dark:text-white mt-2 placeholder-gray-400 dark:placeholder-gray-500"
                  placeholder={`Your ${link.name} URL (https://)`} />

                {/* 🔹 Editable Description Input */}
                <Input
                    type="text"
                    value={editedLinks[link.id]?.description || link.description || ""}
                    onChange={(e) => handleDescriptionChange(link.id, e.target.value)}
                    className="dark:bg-[#222] bg-[#eee] p-2 rounded-lg text-sm w-full border-0 dark:text-white mt-2 placeholder-gray-400 dark:placeholder-gray-500"
                    placeholder="Add a short description..."
                  />



                <Button onClick={() => handleClear(link.id)} className="mt-2 bg-gradient-to-r from-blue-600 to-yellow-600 text-white p-3 w-full border-0 rounded-lg">
                  Clear URL
                </Button>
                <Button onClick={() => handleSaveChanges(link.id)} className="mt-2 bg-gradient-to-r from-blue-600 to-yellow-600 text-white p-3 w-full border-0 rounded-lg">
                  Save
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>

      </div>

      <button
            onClick={handleSignOut}
            className="border-2 z-50 m-20 p-1 rounded-lg"
          >
                      <p className="px-12 py-1 flex-row dark:text-white flex items-center" >
            Sign Out
            </p>
          </button>

          <div className="w-[100%] dark:bg-gradient-to-r from-black to-stone-700" >
          <Shop title={"me"} />



            

      <button
            onClick={handleDeleteProfile}
            className=" border-red-600 border-2 z-50 p-1 rounded-lg"
          >
                      <p className="px-12 py-1 flex-row dark:text-white flex items-center" >
            Delete My Account
            </p>
          </button>
          </div>
    </div>

    </div>
    </>
  );
};

export default Profile;
