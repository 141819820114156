import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db, storage, auth } from "../firebaseConfig";
import { Button, Upload, message, Tooltip, Modal } from "antd";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImageHelper"; // Image cropping helper function

const CoverEdit = () => {
  const navigate = useNavigate();
  const currentUser = auth?.currentUser;
  const [profileData, setProfileData] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [loading, setLoading] = useState(false);

  // Image cropping states
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cropModalVisible, setCropModalVisible] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [useOriginalImage, setUseOriginalImage] = useState(false);

  // Fetch profile data
  useEffect(() => {
    const subscriber = db
      .collection("userss")
      .doc(currentUser?.uid)
      .onSnapshot((documentSnapshot) => {
        setProfileData(documentSnapshot.data());
      });

    return () => subscriber();
  }, [currentUser]);

  /** ✅ Handle Image Selection */
  const onSelectFile = (file) => {
    if (file) {
      setOriginalImage(file); // Store the original image
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImageSrc(reader.result);
        setCropModalVisible(true);
      };
    }
  };

  /** ✅ Handle Cropping */
  const onCropComplete = useCallback(async (_, croppedAreaPixels) => {
    try {
      const croppedImageFile = await getCroppedImg(imageSrc, croppedAreaPixels);
      setCroppedImage(croppedImageFile);
      setUseOriginalImage(false);
    } catch (error) {
      console.error("Crop error:", error);
    }
  }, [imageSrc]);

  /** ✅ Handle Image Upload */
  const handleImageUpload = async () => {
    setLoading(true);
    try {
      const imageRef = ref(storage, `profileImages/cover/${currentUser.uid}`);
      let fileToUpload;

      if (useOriginalImage && originalImage) {
        fileToUpload = originalImage;
      } else if (croppedImage) {
        fileToUpload = croppedImage;
      } else {
        message.error("No image selected for upload.");
        return;
      }

      await uploadBytes(imageRef, fileToUpload);
      const imageUrl = await getDownloadURL(imageRef);

      await updateDoc(doc(db, "userss", currentUser.uid), {
        profileCover: imageUrl,
        profileCoverColor: null, // Reset color when uploading an image
      });

      message.success("Image updated successfully!");
    } catch (error) {
      message.error("Error uploading image: " + error.message);
    } finally {
      setLoading(false);
      setCropModalVisible(false);
    }
  };

  const resetCover = async () => {
    try {
      await updateDoc(doc(db, "userss", currentUser.uid), {
        profileCoverColor: null,
        profileCover: null,
      });
      setSelectedColor(null);
      message.success("Cover reset successfully!");
    } catch (error) {
      message.error("Error resetting cover: " + error.message);
    }
  };

  /** ✅ Handle Color Selection */
  const handleColorSelection = async (color) => {
    try {
      setSelectedColor(color);
      await updateDoc(doc(db, "userss", currentUser.uid), {
        profileCoverColor: color,
        profileCover: null, // Reset image when choosing a color
      });

      message.success("Cover color updated successfully!");
    } catch (error) {
      message.error("Error updating color: " + error.message);
    }
  };

  const colors = ["#FF5733", "#33FF57", "#3357FF", "#F3FF33", "#FF33A8", "#33FFF6", "#D633FF", "#33FF99", "#FF9A33", "#FF3333"];

  return (
    <div className="min-h-screen flex flex-col justify-center p-3 items-center dark:bg-black dark:text-white bg-white">
      <h2 className="text-2xl font-bold mb-2 mt-20">Change Cover Background</h2>

      <div className="flex justify-center items-center m-4">
        {profileData?.profileCover ? (
          <img src={profileData.profileCover} alt="Profile" className="w-40 h-60 rounded-lg object-cover" />
        ) : (
          <div
            style={{ backgroundColor: selectedColor || profileData?.profileCoverColor || "#ccc" }}
            className="w-40 h-60 rounded-lg"
          />
        )}
      </div>

      {/* Upload Section */}
      <div className="text-center">
        <Upload beforeUpload={(file) => { onSelectFile(file); return false; }} listType="picture" maxCount={1}>
          <Button type="dashed" className="text-[#555]">Select Cover Photo</Button>
        </Upload>

        {(croppedImage || originalImage) && (
          <Button type="primary" className="bg-gradient-to-r from-violet-600 to-indigo-600 border-none text-white mt-4"
            onClick={handleImageUpload} loading={loading}>
            Upload Image
          </Button>
        )}
      </div>

      {/* Color Selection */}
      {/* <div className="mt-8">
        <h3 className="font-bold mb-2">Choose a Background Color</h3>
        <div className="flex space-x-2">
          {colors.map((color) => (
            <Tooltip key={color} title={color}>
              <button className="w-10 h-10 rounded-full border-2" style={{ backgroundColor: color }} onClick={() => handleColorSelection(color)}></button>
            </Tooltip>
          ))}
        </div>
      </div> */}

         {/* Reset Cover Button */}
      <button onClick={resetCover}  className="px-6 py-2 mt-5 w-60 border-2 rounded-full">
      Reset Cover
      </button>
      <button onClick={() => navigate("/profile")} className="px-6 py-2 mt-5 w-60 border-2 rounded-full">
        Go Back
      </button>

      {/* Cropping Modal */}
      <Modal open={cropModalVisible} onCancel={() => setCropModalVisible(false)}>
        <div className="relative w-full h-80">
          <Cropper image={imageSrc} crop={crop} zoom={zoom} aspect={9 / 16} // ✅ Phone wallpaper aspect ratio
            onCropChange={setCrop} onZoomChange={setZoom} onCropComplete={onCropComplete} />
        </div>
        <div className="flex justify-between mt-4">
          <Button onClick={() => { setUseOriginalImage(true); setCropModalVisible(false); }} className="bg-gray-500 text-white">
            Use Original Image
          </Button>
          <Button type="primary" onClick={() => setCropModalVisible(false)} className="bg-indigo-600 text-white">
            Confirm Crop
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default CoverEdit;
